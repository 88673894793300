@use '@queries' as *;
@use '@fixed-size' as *;
@use '@typography' as *;
@use '@button' as *;

.card-item-offer {
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  height: max-content;
  justify-content: space-between;
  gap: 12px;

  &:hover {
    .item {
      .item--variant {
        scale: 1.416;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    width: 100%;
    gap: 6px;
    //padding: 4px 0;
    .price-info-container {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .info-wrapper {
        display: flex;
        flex-direction: column;
        .age {
          text-transform: capitalize;
          @include typography-s;
          @include weight-medium;
          color: var(--cst-cards-secondary-type-text-default);
        }
        .price-info {
          display: flex;
          gap: 6px;
          align-items: center;
          width: max-content;

          .current {
            color: var(--cst-cards-item-offer-new-price);
            @include typography-m;
            @include weight-bold;

            @include min-1440-break {
              @include typography-xl;
            }
          }

          .old {
            color: var(--cst-cards-item-offer-old-price);
            @include typography-s;
            @include weight-semi-bold;
          }
        }
      }
    }
  }

  .item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 10px;
    @include singleFixedSize(78px);

    @include min-1440-break {
      @include singleFixedSize(88px);
    }

    .background {
      @include singleFixedSize(56.67px);

      @include min-1440-break {
        @include singleFixedSize(75px);
      }
    }

    .item--variant {
      @include singleFixedSize(48px);

      @include min-1440-break {
        @include singleFixedSize(68px);
      }
    }
  }
}

.properties {
  display: flex;
  padding: 8px 10px;
  margin-top: 2px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: var(--cornerradius10);
  box-shadow: inset 0 0 0 2px var(--cst-tabs-cards-outline-active);
  @include fixedSize(max-content, 34px);

  @include min-1440-break {
    padding: 10px;
    @include fixedSize(max-content, 38px);
  }
}
